<template>
  <div class="d-flex flex-column flex-grow-1">
    <document-toolbar
      :tab="tab"
      :save="saveDocument"
      :revert="refreshDocument"
      v-on="$listeners"
      no-history
      :show-delete="userIsMasterAdmin && tab.isPristine"
      :delete-document="deleteWorkQueue"
    />
    <document-in-tab>
      <cot-form
        class="work-queue-infos"
        v-model="isFormValide"
        ref="form"
        :disabled="readonly"
      >
        <v-card class="card-width mb-1">
          <v-card-title>
            <span>{{$t('t.WorkQueue')}}</span>
          </v-card-title>
          <v-card-text>
            <div class="d-flex justify-space-around">
              <div class="flex-grow-1 pr-4">
                <div class="d-flex flex-direction column">
                  <v-text-field
                    :label="$t('t.Name')"
                    v-model="computedName"
                    class="ml-5"
                    :rules="rules"
                  />
                  <v-text-field
                    :label="$t('t.ImportKey')"
                    v-model="computedImportKey"
                    :rules="rules"
                    class="ml-5"
                  />
                </div>
                <div class="d-flex flex-direction column">
                  <v-checkbox
                    :disabled="isCurrentDefault"
                    hide-details
                    v-show="!computedIsImportLocked"
                    v-model="computedIsDefault"
                    :label="$t('t.IsDefault')"
                    class="ml-5"
                  />
                  <v-checkbox
                    hide-details
                    v-show="!computedIsDefault"
                    v-model="computedIsImportLocked"
                    :label="$t('t.WorkqueueLocked')"
                    class="ml-5"
                  />
                </div>
                <v-card
                  class="mt-10"
                  elevation="1"
                >
                  <v-card-title class="subtitle-2">{{$t('t.Signature')}}</v-card-title>
                  <v-card-text>
                    <content-editor
                      :height="'300px'"
                      :width="'100%'"
                      class="mb-2 mt-2"
                      :content.sync="computedSignature"
                    />
                  </v-card-text>
                </v-card>
              </div>
              <div class="properties">
                <v-card>
                  <v-card-text>
                    <work-queue-properties
                      v-model="computedProperties"
                      :readonly="readonly"
                    />
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </cot-form>
    </document-in-tab>
  </div>
</template>

<script>

import Search from '@/pages/search/controllers'
import DocEdit from '@/mixins/document-editor'

export default {
  mixins: [DocEdit],
  components: {
    CotForm: () => import('@/components/cot-form'),
    ContentEditor: () => import('@/components/content-editor'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    WorkQueueProperties: () => import('./work-queue-properties')
  },
  computed: {
    computedProperties: {
      get () {
        return {
          assigned: this.dataAssigned,
          mainAgentId: this.dataMainAgentId,
          visible: this.dataVisible
        }
      },
      set (v) {
        this.dataAssigned = v?.assigned
        this.dataMainAgentId = v?.mainAgentId
        this.dataVisible = v?.visible
        this.dataDocumentEmit()
      }
    },
    computedImportKey: {
      get () {
        return this.dataImportKey
      },
      set (v) {
        this.dataImportKey = v
        this.dataDocumentEmit()
      }
    },
    computedName: {
      get () {
        return this.dataName
      },
      set (v) {
        this.dataName = v
        this.dataDocumentEmit()
      }
    },
    computedSignature: {
      get () {
        return this.dataSignature
      },
      set (v) {
        this.dataSignature = v
        this.dataDocumentEmit()
      }
    },
    computedIsDefault: {
      get () {
        return this.dataIsDefault
      },
      set (v) {
        this.dataIsDefault = v
        this.dataDocumentEmit()
      }
    },
    computedIsImportLocked: {
      get () {
        return this.dataIsImportLocked
      },
      set (v) {
        this.dataIsImportLocked = v
        this.dataDocumentEmit()
      }
    },
    actionItem () {
      return {
        id: this.id,
        type: this.tab.documentType
      }
    },
    userIsMasterAdmin () {
      return this.$store.getters.isMasterAdmin()
    },
    cacheType () {
      return DocEdit.CacheType.WorkQueueDetail
    },
    readonly () {
      return !this.$store.getters.currentUserHasPermission('WorkQueueSettings')
    },
    isFormValide: {
      get () {
        return this.dataForm
      },
      set (v) {
        this.dataForm = v
        this.$emit('document-can-save', { tab: this.tab, value: Boolean(v) })
      }
    },
    id () {
      return this.tab.documentId
    }
  },
  data () {
    return {
      dataAssigned: [],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataForm: false,
      dataImportKey: null,
      dataIsDefault: false,
      dataIsImportLocked: false,
      dataMainAgentId: null,
      dataName: null,
      dataSignature: null,
      dataVisible: [],
      isCurrentDefault: undefined,
      rules: [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 100 || this.$t('t.MaxLength', { value: 100 })
      ]
    }
  },
  methods: {
    emitDocument () {
      const doc = {
        assigned: this.dataAssigned,
        importKey: this.dataImportKey,
        isDefault: this.dataIsImportLocked ? false : this.dataIsDefault,
        isImportLocked: this.dataIsDefault ? false : this.dataIsImportLocked,
        mainAgentId: this.dataMainAgentId,
        name: this.dataName,
        signature: this.dataSignature,
        visible: this.dataVisible
      }

      if (doc.assigned.length === 0) {
        doc.mainAgentId = null
      }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.document = doc
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    async deleteWorkQueue () {
      Search.getActions(this.actionItem)
        .get('delete-workqueue')
        .executeQuick(this.actionItem, () => this.$router.replace('/'))
    },
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        const defaultWorkQueue = await this.$http().get('/core/v6/work-queues/defaults')
        this.document = Object.assign({}, this.document, {
          name: null,
          importKey: null,
          isDefault: false,
          isImportLocked: false,
          mainAgentId: null,
          assigned: [],
          visible: defaultWorkQueue?.data?.visible || []
        })
      } else {
        if (!this.document?.id) { return }

        this.$store.commit('setModule', {
          name: this.document?.name,
          icon: 'i.WorkQueues'
        })
      }

      await this.$waitFor(() => this.$refs.form)
        .then(form => setTimeout(() => {
          form.validate()
          this.$emit('document-ready', this.tab)
        }, 500))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    async save () {
      const apiUrl = '/core/v6/work-queues'

      if (this.documentIsNew) {
        const response = await this.$http().post(apiUrl, this.document)

        this.$nextTick(() => {
          this.$router.push('/work-queues/' + response.data?.id)
        })

        return response
      } else {
        return this.$http().put(`${apiUrl}/${this.id}`, this.document)
      }
    }
  },
  props: {
    tab: Object
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        this.dataAssigned = v?.assigned || []
        this.dataImportKey = v?.importKey
        this.dataIsDefault = v?.isDefault || false
        this.dataIsImportLocked = v?.isImportLocked || false
        this.dataMainAgentId = v?.mainAgentId
        this.dataName = v?.name
        this.dataSignature = v?.signature
        this.dataVisible = v?.visible || []

        if (Object.keys(v).length && typeof this.isCurrentDefault === 'undefined') {
          this.isCurrentDefault = this.dataIsDefault
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.work-queue-infos
  display flex
  overflow auto
  flex-grow 1
  flex-wrap wrap
  width 100%
  height 100%

.card-width
  width calc(100% - 12px)
  margin 0 auto 12px

.properties
  width 30%
</style>
